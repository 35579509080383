<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <headertoolbar></headertoolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="original_main_container">
        <ion-header collapse="condense"></ion-header>
        <ion-icon class="l_close_a" button @click="this.$router.push('/admin/dashboard')"
          src="assets/ICONOS/close.svg"></ion-icon>

              <p class="tabTitle">Evidencias de Spots
                <ion-icon class="config_icon_question" src="assets/ICONOS/questionmark.svg" 
                    @click="openInfoModal('Evidencias de Spots', 'Este reporte muestra la cantidad de veces que se reprodujo cada Spot, dentro de un periodo de tiempo no mayor a un mes. Para obtener información más precisa, también puede filtrar por sucursal. Si selecciona todas las sucursales, el reporte mostrará una lista donde los spots pueden repetirse por día (conflictuando el hecho de que el spot puede salir en múltiples sucursales el mismo día en este reporte). Por eso, se recomienda generar este reporte por sucursal o por base.', 

[
  {
    id: 0,
    mrg: 10,
    name: 'La tabla cuenta con una función de filtrado que facilita la búsqueda de contenidos específicos. También tiene un botón de \'Escuchar\' que le permite reproducir el audio del spot para identificarlo si el título no es lo suficientemente claro, y un botón de \'Detalles\' que muestra una tabla emergente con las reproducciones, especificando la fecha, hora y el valor Token (que indica el orden de reproducción del día).'
  }
])"
                    />
                  </p>

                <select class="channel_select_e" ref="selectedChannel" v-model="selectedChannel" @change="handleChannelChange" aria-label="Canal" interface="popover" placeholder="Selecciona el canal">
                  <option v-for="item in channel_name_list" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>

                <ion-grid class="log_details_g">
                  <ion-row>
                    <ion-col class="log_date_div_es">

                        <ion-datetime v-model="date_from" class="spot_date_select spot_date_select_from"  :class="date_validation" @ionChange="handleDateChange" :min="minDate" :max="maxDate" display-format="DD/MM/YYYY" picker-format="DD/MM/YYYY" cancel-text="Cancelar" done-text="Aceptar"></ion-datetime>
                        
                        <ion-datetime v-model="date_to" class="spot_date_select spot_date_select_to"  :class="date_validation" @ionChange="handleDateChange" :min="minDate" :max="maxDate" display-format="DD/MM/YYYY" picker-format="DD/MM/YYYY" cancel-text="Cancelar" done-text="Aceptar"></ion-datetime>

                        <p class="alertaFechas" v-show="date_validation === 'menor'">
                          La fecha de inicio no puede ser mayor a la fecha final.
                        </p>
                        <p class="alertaFechas" v-show="date_validation === 'mayor'">
                          No se pueden consultar mas de 31 días en un solo reporte.
                        </p>
                        
                    </ion-col>
                    <ion-col class="spot_log_select_div">
                      <select class="spot_log_select_e" v-model="selectedlog" @change="handlelogChange" aria-label="Canal" interface="popover" placeholder="Selecciona el canal">
                          <option  key="Todos" value="Todos">
                              Todos
                          </option>
                          <option v-for="item in log_list" :key="item.id" :value="item[0]">
                              {{ item[1] }}
                          </option>
                    </select>
                    </ion-col>
                  </ion-row>
                </ion-grid>
                
                <button class="cargar_reportes_button sc-btn-3" @click="cargarReportes()">
                  <span>
                    Cargar Reportes
                  </span>
                </button>
                
                <ion-card v-if="(log_list.length<=0 && isloading == false) || alertaSinArvhivos " color="warning" class="connectionalert outline-card" ouline>
                  <ion-card-header>
                    <ion-card-title>Sin Registros.</ion-card-title>
                  </ion-card-header>
                  <ion-card-content>
                    No existen registros para la fecha seleccionada.
                  </ion-card-content>
                </ion-card>

                

                <div class="out_loader" v-if="isloading">
                  <div class="loader"></div>
                </div>

                <div class="log_count_l" v-show="log_s_table.length > 0 && !isloading">
                  Registros mostrados: {{ filteredLogs.length }} / {{ log_s_table.length }}
                </div>
                <div class="filter-input-con" v-show="log_s_table.length > 0 && !isloading">
                  <ion-input class="filterInput" placeholder="Filtrar por Titulo" @keyup="filterLogs" ref="textFilter"></ion-input>
                  <ion-icon class="filterIcon filtersearch" src="assets/ICONOS/search.svg"></ion-icon>
                  <ion-icon src="assets/ICONOS/close_f.svg" class="filterIcon filterclose" @click="this.$refs.textFilter.value = ''; filterLogs();"></ion-icon>
                </div>
                <div class="log_count_r"  v-show="log_s_table.length > 0 && !isloading">
                  Registros mostrados: {{ filteredLogs.length }} / {{ log_s_table.length }}
                </div>

                <table v-show="log_s_table.length>0 && isloading == false" class="log_s_table">
                  <thead>
                    <tr>
                      <th class="count">Rep.</th>
                      <th class="title">Título</th>
                      <th colspan="2" class="acciones">Acciones</th> 
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in filteredLogs" :key="item.url">
                      <td>{{ item.count }}</td>
                      <td>{{ item.title }}</td>
                      <td class="accioness">
                        <a  class="pp_escucharButton" 
                            @click="escucharAudio(item.url, item.title)" 
                            :data-url="item.url"
                        >
                          <ion-icon class="vvplay" style="height: 20px;" src="assets/ICONOS/play.svg"></ion-icon>
                          <ion-icon class="vvpausa"  style="height: 18px;" src="assets/ICONOS/audios-blanco.svg"></ion-icon>
                        </a>
                      </td>
                      <td class="accioness">
                        <a class="pp_detallesButton"
                          @click="openModal(item.title, item.logs)"
                        >
                          <ion-icon src="assets/ICONOS/detalles.svg"></ion-icon>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>  
                
                <div class="vAudioFloat" v-show="vAudioPlayerVisible">
                  <div class="vAudioDiv">
                    <a @click="vAudioPlayerClose" class="vAudioClose">
                      <ion-icon class="vAudioCloseIcon" src="assets/ICONOS/close_f.svg"></ion-icon>
                    </a>
                    <p>{{ vAudioPlayerName }}</p>
                    <audio id="vAudioPlayer" controls>
                      Your browser does not support the audio element.
                    </audio>
                  </div> 
                </div>
                <div class="bottom-space-on-float-audio" v-show="vAudioPlayerVisible"></div>
                <br>
                <br>
                <br>
      </div>
    </ion-content>
    <!-- <ion-button @click="signOut" class="logoutButtonCabina"><ion-icon src="assets/ICONOS/cabina/log-out-outline.svg"></ion-icon>Cerrar sesión</ion-button> -->
  </ion-page>
</template>


<script>
/* eslint-disable */
import { alertController, IonPage, IonHeader, IonToolbar, IonContent, IonRefresher, IonRefresherContent, IonDatetime, modalController } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from '../header_toolbar.vue';
import { Capacitor } from '@capacitor/core';
import 'url-polyfill';
import 'blob-polyfill'; 
import { max, min } from 'moment-timezone';
import moment from 'moment';


import logListModal from '../logListModal.vue';
import infoModal from '../infoModal.vue';
//import { toastController } from '@ionic/vue';

export default ({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, headertoolbar, IonRefresher, IonRefresherContent, IonDatetime },
  data() {
    return {
      urlApi: 'https://panel.vybroo.net/',
      clientData: null,  
      token: null,
      serverDate: null,
      clientData: {}, 
      channel_name_list: [],
      channel_list: [],
      selectedChannel: null,
      
      log_list: [],
      selectedlog: null,
      isloading: true,
      dashboard: 
        {
          client: {
            email: "",
            id: 0,
            name: "",
            obligation: 0,
            retainer: 0
          },
          radio: {
            blacklist: "",
            branches: 0,
            categories: "",
            image: "2f07f7cfc8d4348937078a566c7d943e.png",
            link: "",
            mobileimage: "6b8b53effc9683373db10671d4bf0687.png",
            name: "",
            tags_blacklist: "",
            video_enabled: false
          },
          spots: {
            id: 0,
            level: 0,
            month: "",
            name: "",
            obligation: 0,
            percentage: "",
            produced: null,
            year: ""
          },
        },
        date_from: moment().startOf('month').format('YYYY-MM-DD'),
        date_to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        minDate: '2023-01-01',
        maxDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        date_validation: 'ok',
        log_s_table: [],
        filteredLogs: [],
        logTitleObj: {},
        muteado: false,
        vAudioPlayerName: null,
        vAudioPlayerVisible: false,
        alertaSinArvhivos: false,

    }
  },
  methods: { 
    async openInfoModal(title, desc, list) {
      const modal = await modalController.create({
        component: infoModal,
        componentProps: {
          title: title,
          desc: desc,
          list: list,
        },
      });
      return modal.present();
    },
    async openModal(title, array) {
      const modal = await modalController.create({
        component: logListModal,
        componentProps: {
          title: title,
          array: array,
        },
      });
      return modal.present();
    },
    vAudioPlayerClose() {
      const escucharButton = document.querySelectorAll('.pp_escucharButton');
      escucharButton.forEach(element => {
        element.classList.remove('activo');
      });
      const audio = document.getElementById('vAudioPlayer');
      audio.pause();
      this.vAudioPlayerVisible = false;
    },
    async escucharAudio(url, name) {
      if (this.muteado == true) {
        this.reproducirAudio(url, name);
      }else{
          try {
            const alert = await alertController
              .create({
                header: "Aviso",
                message: "¿Deseas reproducir este audio? se pausará el reproductor principal.",
                buttons: [
                  {
                    text: 'Si',
                    handler: () => {  
                      this.reproducirAudio(url, name);
                      this.muteado = true;
                    }
                  },
                  {
                    text: 'Cancelar',
                    handler: () => {
                      console.log('Cancelar pulsado');
                    }
                  },
                ]
              });
            return alert.present();
          } catch (e) {
            this.presentAlertConfirm("Ha ocurrido un error::escucharAudio:", e);
          } 
      }
    },
    reproducirAudio (url, name) {
      const escucharButton = document.querySelectorAll('.pp_escucharButton');
      escucharButton.forEach(element => {
        element.classList.remove('activo');
      });
      const escucharButtonActivo = document.querySelector(`.pp_escucharButton[data-url="${url}"]`);
      escucharButtonActivo.classList.add('activo'); 

      this.vAudioPlayerVisible = true;
      this.emitter.emit("rep-func-pause", "Muteando Reproductor");
      const audio = document.getElementById('vAudioPlayer');
      audio.src = 'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev' + url.substring(2);
      audio.play();
      this.vAudioPlayerName = name;
      audio.onended = () => {
        console.log('EL AUDIO HA TERMINADO');
        escucharButtonActivo.classList.remove('activo');
      };
    },
    filterLogs() {
      let filter = this.$refs.textFilter.value.toLowerCase();
      this.filteredLogs = [];
      this.filteredLogs = this.log_s_table.filter(log => {
        return log.title.toLowerCase().includes(filter);
      }); 
    },
    handleChannelChange(event) { 
      this.selectedChannel = event.target.value;
      this.emitter.emit("selectedChannel", event.target.value);
              Storage.set({
                key: "cabina_admin_selectedChannel",
                value: this.selectedChannel,
              }); 
      setTimeout(() => {
        //this.getLogs();
        this.refreshLogList();
      }, 1000);
    },
    firstLoad() { 
      this.selectedChannel = this.$refs.selectedChannel.value;
      console.log("firstLoad::DASHBOARD: ", this.selectedChannel);
      this.emitter.emit("selectedChannel", this.selectedChannel);
              Storage.set({
                key: "cabina_admin_selectedChannel",
                value: this.selectedChannel,
              }); 
      setTimeout(() => {
        //this.getLogs();
        this.refreshLogList();
      }, 1000);
    },
    handleDateChange() {
      console.log("handleDateChange::Obteniendo handleDateChange...");
      this.date_from = moment(this.date_from).format('YYYY-MM-DD');
      this.date_to = moment(this.date_to).format('YYYY-MM-DD');
      if (this.date_from > this.date_to) {
         this.date_validation = 'menor';
      } else {
        if (moment(this.date_to).diff(moment(this.date_from), 'days') > 31) {
          this.date_validation = 'mayor';
        }else{
          this.date_validation = 'ok';
        }
      }
    },
    handlelogChange(event) {
      setTimeout(() => {
        //this.getListLogs();
      }, 1000);
    },
    async refreshLogList() {
      this.isloading = true;
      console.log("handleChannelChange::DASHBOARD: ", this.dashboard);

        const con = [];
        con.push(["Base", "Base"]);
        const ContentVariants = this.dashboard.radio.ContentVariants;
        if (ContentVariants) {
          ContentVariants.forEach(cvar => {
            const con_aux = [`${cvar.id}`,`${cvar.name}`];
            con.push(con_aux);
          });
  
          this.log_list = con;
          
                // ordenar numerica y alfabeticamente excepto base, siempre debe estar al tope 
                this.log_list.sort((a, b) => {
                  if (a[0] === "Base") {
                    return -1;
                  }
                  if (b[0] === "Base") {
                    return 1;
                  }
                  return a[1].localeCompare(b[1]);
                });

          this.selectedlog = "Todos";
          console.log("Combined Array: ", con);
          this.isloading = false;
        }else{
          this.firstLoad();
        }
    },
    async cargarReportes() {
      this.alertaSinArvhivos = false; 
      console.log("this.date_from: ", this.date_from);
      console.log("this.date_to: ", this.date_to);
    if (this.date_from > this.date_to) {
      console.log("cargarReportes::Fecha de inicio mayor a fecha final");
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "La fecha de inicio no puede ser mayor a la fecha final.",
          buttons: [
            {
              text: 'Aceptar',
              handler: () => {
                //console.log('Aceptar pulsado');
              }
            },
          ]
        }); 
        return alert.present();
    } else { 
    if (moment(this.date_to).diff(moment(this.date_from), 'days') > 31) {
      console.log("cargarReportes::Rango de fechas mayor a 31 días");
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "El rango de fechas no puede ser mayor a 31 días.",
          buttons: [
            {
              text: 'Aceptar',
              handler: () => {
                //console.log('Aceptar pulsado');
              }
            },
          ]
        }); 
        return alert.present();
    } else {
      this.isloading = true;
      this.log_s_table = [];
      this.logTitleObj = {};
      try {
        console.log("cargarReportes::Obteniendo cargarReportes...");
        var videoAux = '';
            if (this.dashboard.radio.video_enabled) {
              videoAux = '_v';
            }
        const stream = this.dashboard.radio.stream+videoAux;
        const from = this.date_from;
        const to = this.date_to;
        const log = this.selectedlog;

        const ContentVariants = this.dashboard.radio.ContentVariants;
        const MusicVariants = this.dashboard.radio.MusicVariants;

        const conmus = [];
        const con = [];
        const mus = [];

        if (log == "Todos" || log == "Base") {
          conmus.push([`${stream}`, `Base`]);
        }

        ContentVariants.forEach(cvar => {
          MusicVariants.forEach(mvar => {
            if (log == "Todos" || log == cvar.id) {
              const conmus_aux = [`${stream}__${cvar.id}==${mvar.id}`, `${cvar.name} - ${mvar.name}`];
              conmus.push(conmus_aux);
            }
          });
        });

        ContentVariants.forEach(cvar => {
          if (log == "Todos" || log == cvar.id) {
            const con_aux = [`${stream}__${cvar.id}`, `${cvar.name}`];
            con.push(con_aux);
          }
        });

        MusicVariants.forEach(mvar => {
          if (log == "Todos" || log == "Base") {
            const mus_aux = [`${stream}==${mvar.id}`, `${mvar.name}`];
            mus.push(mus_aux);
          }
        });

        const combinedArray = conmus.concat(con, mus);
        console.log("Combined Array: ", combinedArray);

        // Array para almacenar las promesas de las peticiones axios
        const promises = [];

        combinedArray.forEach(sLink => {
          const fromDate = new Date(from);
          const toDate = new Date(to);
          //fromDate.setDate(fromDate.getDate() + 1);
          //toDate.setDate(toDate.getDate() + 1);
          let currentDate = new Date(fromDate);

          while (currentDate <= toDate) {
            const formattedDate = currentDate.toISOString().split('T')[0];
            	
            const url = `https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/play_logs/${sLink[0]}/${formattedDate}.txt`;

            const promise = axios.get(url)
              .then(response => {
                const textLogList = response.data.split('\n');
                textLogList.forEach(log => {
                  if (log.length > 10) {
                    const logParts = log.split('[');
                    const logTime = logParts[0].trim().substring(0, 8);
                    const logToken = logParts[0].trim().substring(8).replace(/\s/g, '');
                    const logType = logParts[1].trim().slice(0, -1);
                    const logCategory = logParts[3].trim().slice(0, -1);
                    const logFilter = logParts[4].trim().slice(0, -1);
                    const logFormula = logParts[5].trim().slice(0, -1);
                    const logTitle = logParts[6].trim().slice(0, -1);
                    const logArtist = logParts[7].trim().slice(0, -1);
                    const logUrl = logParts[8].trim().slice(0, -1);

                    if (logType == "spot") {
                      if (this.logTitleObj.hasOwnProperty(logTitle)) {
                        this.logTitleObj[logTitle].count++;
                        this.logTitleObj[logTitle].logs.push({
                          date: formattedDate,
                          hora: logTime,
                          token: logToken
                        });
                      } else {
                        this.logTitleObj[logTitle] = {
                          url: logUrl,
                          count: 1,
                          logs: [{
                            date: formattedDate,
                            hora: logTime,
                            token: logToken
                          }]
                        };
                      }
                    }
                  }
                });
              })
              .catch(error => {
                console.log("Error en función cargarReportes de cargarReportes: " + error.message);
              });

            promises.push(promise);
            currentDate.setDate(currentDate.getDate() + 1);
          }
        });

        await Promise.all(promises); // Espera a que todas las peticiones se completen

        // Transformar log_s_table
        this.log_s_table = Object.keys(this.logTitleObj)
          .map(key => ({
            title: key,
            url: this.logTitleObj[key].url,
            count: this.logTitleObj[key].count,
            logs: this.logTitleObj[key].logs
          }))
          .sort((a, b) => b.count - a.count);
        
        //if this.log_s_table is empty, show alert in console
        if (this.log_s_table.length <= 0) {
          console.log("No existen registros para la fecha seleccionada.");
          this.alertaSinArvhivos = true;
        }

        this.filterLogs();
        this.isloading = false;

      } catch (error) {
        console.log("Error en función cargarReportes de cargarReportes: " + error.message);
      }
    }}
    },

    async getLogs() {
      this.isloading = true;
      this.log_s_table = [];
      try {
        console.log("getLogs::Obteniendo getLogs...");
        //https://panel.vybroo.net/get_available_logs
        
        this.isloading = false;
      } catch (error) {
        console.log("Error en función getLogs de Conexiones: " + error.message);
      }
    },
    async presentAlertConfirm(headerAlert, messageAlert, buttonTextConfirm, buttonTextCancel, functionConfirm, functionCancel) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: [
            {
              text: buttonTextConfirm,
              handler: () => {
                functionConfirm();
              }
            },
            {
              text: buttonTextCancel,
              handler: () => {
                functionCancel();
              }
            }
          ]
        });
      return alert.present();
    },
    async signOut() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "¿Estás seguro que deseas cerrar la sesión?",
          buttons: [
            {
              text: 'Si',
              handler: () => {
                Storage.remove({ key: 'authToken' });
                Storage.remove({ key: 'cabina_admin_user' });
                Storage.remove({ key: 'cabina_admin_channel_list' });
                Storage.remove({ key: 'cabina_admin_selectedChannel' });
                this.$router.replace("/admin/LoginAdmin");
                this.emitter.emit('cabina_admin_user', '',);
                this.emitter.emit('cabina_admin_channel_list', '',);
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                //console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();

    }, 
    async getStorageClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        this.clientData = this.isJson(ret.value);
      } catch (error) {
        //console.log("Error en función getStorageClientData de Conexiones: " + error.message);
      }
    }, 
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
    async getStorageAuthToken() {
      try {
        //console.log("Obteniendo token del storage...")
        const ret = await Storage.get({ key: 'authToken' });
        if (ret.value) {
          const tokenObj = JSON.parse(ret.value);
          this.token = tokenObj.value;
          //console.log("Token obtenido: " + this.token);
          // console.log("Token obtenido:" + this.token)
          return this.validateToken(this.token);
        } else {
          //console.log("Token no encontrado");
          this.presentAlertTokenInvalid();
          return false;
        }
      } catch (error) {
        //console.log("Error en función getStorageAuthToken de Conexiones: " + error.message);
      }
    },
    async presentAlertTokenInvalid() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "Tu sesión ha expirado, por favor inicia sesión nuevamente",
          buttons: [
            {
              text: 'Aceptar',
              handler: () => {
                this.$router.replace("/admin/LoginAdmin");
              }
            },
          ]
        });
      return alert.present();
    },
    async validateToken(token) {
      try {
        //console.log("revisando validez de token: " + token + " ...");
        if (token == null || token == undefined || token == "") {
          this.presentAlertTokenInvalid();
          return false;
        }
        if (token != null && token != undefined && token != "") {
          let url = "api/user_auth";
          if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/user_auth";
          let response = await axios.post(url, { token: token })
            .then(r => {
              //console.log("r.data:");
              //console.log(r.data);
              return r;
            }).catch(error => {
              //console.log("3Error en función validateToken de Conexiones: " + error.message);
              return error;
            });
          //console.log("response.data:");
          //console.log(response.data);
          //console.log("response.data.success: " + response.data.success);
          //console.log("response.data.error: " + response.data.error);
          if (response.data.error === true) {
            //console.log("Error al validar token:");
            //console.log(response.data);
            this.presentAlertTokenInvalid();
            return false;
          }
          if (response.data.success === true) {
            //console.log("Token válido");
            return true;
          } else {
            //console.log("Token inválido");
            //console.log("2response.data.success: " + response.data.success);
            //console.log("2response.data.error: " + response.data.error);
            this.presentAlertTokenInvalid();
            return false;
          }
        }
      } catch (error) {
        //console.log("4Error en función validateToken de Conexiones: " + error.message);
        return false;
      }
    },
    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          //console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          //console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
    async getChannelList(){
      try {
        const ret = await Storage.get({ key: "cabina_admin_channel_list" });
        if (ret.value) { 
            this.channel_list = JSON.parse(ret.value).slice();
            this.emitter.emit("channel_list", this.channel_list);
            this.channel_name_list = [];
            if (this.channel_list){
              this.channel_list.forEach(element => {
                this.channel_name_list.push({ id: element.id, name: element.name });
              });
              this.emitter.emit("channel_name_list", this.channel_name_list);
              //console.log("Recuperado de storage, key: cabina_admin_channel_list - " , this.channel_list);
              //console.log("Recuperado de storage, key: channel_name_list - " , this.channel_name_list);
              if (this.selectedChannel == [] || this.selectedChannel == null || this.selectedChannel == undefined) {
                const selectedChannelAux = await Storage.get({ key: "cabina_admin_selectedChannel" });
                //console.log("selectedChannelAux: " , selectedChannelAux);
                if (selectedChannelAux.value) {
                  this.selectedChannel = selectedChannelAux.value;
                  this.emitter.emit("selectedChannel", this.selectedChannel);
                  Storage.set({
                    key: "cabina_admin_selectedChannel",
                    value: this.selectedChannel,
                  });
                } else {
                  this.selectedChannel = this.channel_list[0].id;
                  this.loadChannelDashboard(this.selectedChannel);
                  this.emitter.emit("selectedChannel", this.selectedChannel);
                  Storage.set({
                    key: "cabina_admin_selectedChannel",
                    value: this.selectedChannel,
                  });
                }
                //this.selectedChannel = this.channel_list[0].id;
                //this.loadChannelDashboard(this.selectedChannel);
                //this.emitter.emit("selectedChannel", this.selectedChannel);
              }
            }
        } else {
          console.log("No se pudo recuperar de storage, key: cabina_admin_channel_list - " , this.channel_list);
        }
      } catch (error) {
        console.log("Error en función cabina_admin_channel_list de Dashboard: " , error.message);
        return false;
      }
    }
  },
  mounted: function () { 
setTimeout(() => {

    try {
      if (Capacitor.getPlatform() == 'ios') {
        this.urlApi = "https://panel.vybroo.net/";
      } else {
        this.urlApi = "/api/";
      } 

      //get auth token de storage y asignarlo a la variable token
      this.getStorageAuthToken();
      this.getStorageClientData('clientData');
      this.getChannelList();

      this.emitter.on("selectedChannel", (channel) => {
        //console.log("conexiones::::emitedON:selectedChannel: " , channel);
        this.selectedChannel = channel;
        //this.getLogs(); 
      });
      this.emitter.on("channel_list", (channel_list) => {
        //console.log("conexiones::::emitedON:channel_list: " , channel_list);
        this.channel_list = channel_list;
      });
      this.emitter.on("channel_name_list", (channel_name_list) => {
        //console.log("conexiones::::emitedON:channel_name_list: " , channel_name_list);
        this.channel_name_list = channel_name_list;
      });
      this.emitter.on("dashboard", (dashboard) => {
        //console.log("conexiones::::emitedON:dashboard: " , dashboard);
        this.dashboard = dashboard; 
      });
 
    } catch (error) {
      console.log("Error en función mounted de Conexiones: " + error.message);
    }

     
}, 1000);
setTimeout(() => {
  this.firstLoad();
}, 2000);
  },
  

})
</script>
<style lang="css"> 
.bottom-space-on-float-audio {
  height: 100px;
}
.pp_detallesButton {
  cursor: pointer;
  text-align: center;
  width: 22px;
  display: block;
  margin: auto;
}
.pp_escucharButton {
  cursor: pointer;
  text-align: center;
  width: 22px;
  display: block;
  margin: auto;
}
.pp_escucharButton .vvplay {
  display: block;
  margin: auto;
}
.pp_escucharButton .vvpausa {
  display: none;
  animation: pulse .5s infinite;
  margin: auto;
} 
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.pp_escucharButton:hover .vvplay {
  scale: 1.2;
}
.pp_escucharButton:hover .vvpausa {
  scale: 1.2;
}
.pp_escucharButton.activo .vvplay {
  display: none;
}
.pp_escucharButton.activo .vvpausa {
  display: block;
}
.pp_detallesButton ion-icon {
  width: 22px;
  height: 22px;
}
.pp_detallesButton ion-icon:hover {
  scale: 1.2;
}
.vAudioFloat{
  position: fixed;
    bottom: 20px;
    left: 50%;
    background-color: #000000;
    color: #ffffff;
    z-index: 1000;
    border: solid 1px #ccc;
    padding: 0px 15px;
    width: 420px;
    transform: translateX(-50%);
    border-radius: 7px;
    box-shadow: 0px 0px 20px RGBA(0,0,0,.5);
}
.vAudioFloat #vAudioPlayer{
  width: 100%;
}
.vAudioFloat .vAudioDiv {
   padding: 6px 2px 10px 2px;
}
.vAudioFloat .vAudioDiv p {
   width: calc(100% - 20px);
    text-align: left;
    font-size: 1.1em;
    font-weight: 600;
    margin: 0px;
    padding: 10px 0px;  
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.vAudioFloat .vAudioClose{
  position: absolute;
  right: 10px;
  top: 10px;
  color: #ffffff;
  cursor: pointer;

}
.vAudioFloat .vAudioCloseIcon{
  width: 20px;
  height: 20px;
}
.vAudioFloat .vAudioClose:hover{
  scale: 1.2; 
}
 

/* PRETTY BUTTON ------------*/
.cargar_reportes_button {
  width: 130px;
  height: 40px;
  color: #fff;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
  outline: none;
  margin: 20px auto;
  display: block;
}
.sc-btn-3 {
  color: white;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-size: 16px;
  background: linear-gradient(0deg, #2dd36f 0%, #2dd36f 100%);
  width: 180px;
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;

}
.sc-btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.sc-btn-3:before,
.sc-btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background: #2dd36f;
  transition: all 0.3s ease;
}
.sc-btn-3:before {
  height: 0%;
  width: 2px;
}
.sc-btn-3:after {
  width: 0%;
  height: 2px;
}
.sc-btn-3:hover {
  background: transparent;
  color: #2dd36f;
  font-weight: bold;
  box-shadow: none;
}
.sc-btn-3:hover:before {
  height: 100%;
}
.sc-btn-3:hover:after {
  width: 100%;
}
.sc-btn-3 span:hover {
  color: #2dd36f;
}
.sc-btn-3 span:before,
.sc-btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: #2dd36f;
  transition: all 0.3s ease;
}
.sc-btn-3 span:before {
  width: 2px;
  height: 0%;
}
.sc-btn-3 span:after {
  width: 0%;
  height: 2px;
}
.sc-btn-3 span:hover:before {
  height: 100%;
}
.sc-btn-3 span:hover:after {
  width: 100%;
}
/* PRETTY BUTTON ------------*/
.spot_log_select_e {
    width: 100% ;
    margin: 0 0 0 16px;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #232323;
    background-color: #000000;
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 10px;
}
.spot_date_select { 
    margin: 0 0 0 8px;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #232323;
    background-color: #000000;
    color: #ffffff;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 10px;
}
.spot_date_select_from,
.spot_date_select_to {
    width: 50% !important; 
}
.log_date_div_es .alertaFechas {
  color: #ff4343;
    font-size: 12px;
    position: absolute;
    bottom: -43px;
    background: black;
    border-radius: 6px;
    padding: 4px 7px;
    border: solid red 1px;
    width: calc(100% - 20px);
    left: calc(50% + 4px);
    transform: translateX(-50%);
    display: none;
    z-index: 1000;
}
.log_date_div_es:hover .alertaFechas {
  display: block;
}
.log_date_div_es .alertaFechas:before{
  content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid red;
    top: -6px;
    left: calc(50% - 6px);

}
.log_date_div_es{
  width: 280px !important;
  max-width: 280px !important;
  min-width: 280px !important;
  display: flex;
}
.spot_log_select_div{
  width: calc(100% - 300px) !important;
  max-width: calc(100% - 300px) !important;
  min-width: calc(100% - 300px) !important;
}

.log_details_g{ 
  padding: 0px !important;
}

.filter-input-con {   
  margin: 10px 10px;
  border: solid 1px #232323;
  position: relative;
  border-radius: 5px;
  max-width: calc(100% - 22px);
  width: calc(400px - 35px);
}
.filter-input-con .filterInput {
  width: 100%;  
}
.filter-input-con .filterInput input{
  padding-left: 35px;
}
.filter-input-con .filtersearch {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.filter-input-con .filterclose {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 2;
}


.log_count_l {
  position: relative;
    border-radius: 5px;
    width: 280px;
    float: right;
    text-align: right;
    margin-top: 25px;
    display: inherit;
    padding: 0px 10px;
}
.log_count_r {
    position: relative;
    border-radius: 5px;
    width: 100%;
    float: inherit;
    text-align: right;
    margin: 10px auto;
    padding: 0px 10px;
    display: none;
}
.log_s_table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-size: 1em;
  font-weight: 400;
  color: #ffffff;
  background-color: #000000;
  border-radius: 5px;
  border: solid 1px #232323;
}
.log_s_table th {
  padding: 5px;
  border-bottom: solid 1px #232323;
  text-align: left;
}
.log_s_table td {
  padding: 5px;
  border-bottom: solid 1px #232323;
  text-align: left;
}
.log_s_table tr:hover{
  background-color: #232323;
} 

.log_s_table th {
  position: sticky;
  top: 0;
  background-color: #000000;
  z-index: 1;
} 
.log_s_table th:nth-child(1) {
  width: 20px;
} 
.log_s_table .acciones {
  width: 24px;
  text-align: center
}
.log_s_table td.accioness {
  width: 50px;
} 
.log_s_table .count { 
  cursor: help;
}
.log_s_table .count:hover {
  background: #363636;
}
.log_s_table .count:hover:before {
    content: 'Numero de Reproducciones totales';
    position: absolute;
    background-color: #363636;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 12px;
    left: 0;
    top: 25px;
    border: solid 1px #232323;
    z-index: 2000;
}
.log_s_table .accioness .pp_escucharButton,
.log_s_table .accioness .pp_detallesButton{
   position: relative;
}

.log_s_table .accioness .pp_escucharButton:hover ion-icon,
.log_s_table .accioness .pp_detallesButton:hover ion-icon{ 
  filter: brightness(0.8);
}
.log_s_table .accioness .pp_escucharButton:hover:before{
    content: 'Escuchar';
    position: absolute;
    background-color: #363636;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 12px;
    left: 0;
    top: 25px;
    border: solid 1px #232323;
    z-index: 2000;
}
.log_s_table .accioness .pp_escucharButton.activo:hover:before{
    content: 'Escuchando';
}
.log_s_table .accioness .pp_detallesButton:hover:before{  
    content: 'Detalles';
    position: absolute;
    background-color: #363636;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 12px;
    left: 0;
    top: 25px;
    border: solid 1px #232323;
    z-index: 2000;
}
@media (max-width: 767px) {
  .log_s_table {
    width: 100%;
        max-width: 100%;
        height: calc(100vh - 400px);
        overflow: auto !important;
        display: block;
  }
  .log_s_table th {
    position: sticky;
    top: 0;
    background-color: #000000;
    z-index: 1;
  }
  .log_count_l {
    display: none;
  }
  .log_count_r {
      display: inherit;
  }
}



.log_date_div_es > .spot_date_select_from.menor,
.log_date_div_es > .spot_date_select_to.menor {
  border: solid 1px #ff4f4f;
}
.log_date_div_es > .spot_date_select_from.mayor,
.log_date_div_es > .spot_date_select_to.mayor {
  border: solid 1px #ff4f4f;
}
.log_date_div_es > .spot_date_select_from.ok,
.log_date_div_es > .spot_date_select_to.ok {
  border: solid 1px #232323;
}
 

</style>